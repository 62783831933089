import menu from '@/api/menu'
import { i18n } from '@/plugins/i18n'
import { onMounted, ref } from '@vue/composition-api'

export default function useReport() {
  window.document.title = `${i18n.t('Report')} | APSTH Platform Clinic 3.9`
  const saleSelect = ref('')
  const accountingSelect = ref('')
  const costSelect = ref('')
  const customerSelect = ref('')
  const checkSelect = ref('')
  const drugEquipmentSelect = ref('')
  const controlSelect = ref('')
  const reportSaleGroup = ref([])
  const reportAnalysisGroup = ref([])
  const reportDrugGroup = ref([])
  const reportCustomerGroup = ref([])
  const reportCostGroup = ref([])
  const reportAccountGroup = ref([])
  const reportExaminationGroup = ref([])
  const reportWarningGroup = ref([])
  const statusLoaddata = ref(false)
  const containerId = ref('')
  const items = ref([
    { title: 'First', description: 'The first item.' },
    { title: 'Second', description: 'The second item.' },
  ])
  onMounted(() => {
    getMenu()
  })
  const getMenu = () => {
    statusLoaddata.value = false
    menu().then(async res => {
      const menuList = res.find(item => item.group_menu_id == '13')
      const saleGroup = []
      const analysisGroup = []
      const drugGroup = []
      const customerGroup = []
      const costGroup = []
      const accountGroup = []
      const examinationGroup = []
      const warningGroup = []
      menuList.menu.forEach(element => {
        if (['reportcheckanalysis', 'reportsalesanalysis', 'reportdruganalysis'].includes(element.menu_link)) {
          analysisGroup.push({
            path: `/${element.menu_link}`,
            name: i18n.locale == 'th' ? element.menu_th : element.menu_en,
          })
        }
        if (
          ['reportprofit', 'reportsummary', 'reportdrug', 'reportcourse', 'reportcommission', 'reportmember'].includes(
            element.menu_link,
          )
        ) {
          saleGroup.push({
            path: `/${element.menu_link}`,
            name: i18n.locale == 'th' ? element.menu_th : element.menu_en,
          })
        }
        if (
          [
            'reportdispensing',
            'reportlotdrug',
            'reportdrugbalance',
            'reportmovementdrug',
            'reportdruglowest',
            'reportexpired',
            'reporttransfer',
            'poreport',
          ].includes(element.menu_link)
        ) {
          drugGroup.push({
            path: `/${element.menu_link}`,
            name: i18n.locale == 'th' ? element.menu_th : element.menu_en,
          })
        }
        if (
          [
            'reportserve',
            'reportserviceactivity',
            'reportservice',
            'reportservicesummary',
            'reportcustomer',
            'reportappoint',
          ].includes(element.menu_link)
        ) {
          customerGroup.push({
            path: `/${element.menu_link}`,
            name: i18n.locale == 'th' ? element.menu_th : element.menu_en,
          })
        }
        if (
          ['reportpay', 'reportdoctor', 'reportuser', 'reportcommis', 'reportcommistotal'].includes(element.menu_link)
        ) {
          costGroup.push({
            path: `/${element.menu_link}`,
            name: i18n.locale == 'th' ? element.menu_th : element.menu_en,
          })
        }
        if (
          ['reportincomesummary', 'reportdepositsummary', 'invoicesummary', 'reportorderpay', 'reportwallet'].includes(
            element.menu_link,
          )
        ) {
          accountGroup.push({
            path: `/${element.menu_link}`,
            name: i18n.locale == 'th' ? element.menu_th : element.menu_en,
          })
        }
        if (['reportcheck', 'reportlab'].includes(element.menu_link)) {
          examinationGroup.push({
            path: `/${element.menu_link}`,
            name: i18n.locale == 'th' ? element.menu_th : element.menu_en,
          })
        }
        if (
          ['reportpsychotropic2', 'reportpsychotropic3', 'reportpsychotropic4', 'reportpsychotropic5'].includes(
            element.menu_link,
          )
        ) {
          warningGroup.push({
            path: `/${element.menu_link}`,
            name: i18n.locale == 'th' ? element.menu_th : element.menu_en,
          })
        }
      })
      reportSaleGroup.value = saleGroup
      reportDrugGroup.value = drugGroup
      reportCustomerGroup.value = customerGroup
      reportCostGroup.value = costGroup
      reportAccountGroup.value = accountGroup
      reportExaminationGroup.value = examinationGroup
      reportWarningGroup.value = warningGroup
      reportAnalysisGroup.value = analysisGroup
      statusLoaddata.value = true
    })
  }

  return {
    saleSelect,
    accountingSelect,
    costSelect,
    customerSelect,
    checkSelect,
    items,
    drugEquipmentSelect,
    controlSelect,
    reportSaleGroup,
    reportDrugGroup,
    reportCustomerGroup,
    reportCostGroup,
    reportAccountGroup,
    reportExaminationGroup,
    reportWarningGroup,
    statusLoaddata,
    reportAnalysisGroup,
    containerId,
  }
}
