<template>
  <div>
    <v-row v-if='statusLoaddata'>
      <v-col
        v-if='reportSaleGroup.length > 0'
        class='pt-0 '
        cols='12 '
        md='6'
        lg='3'
      >
        <v-card>
          <v-list nav>
            <v-list-item class='my-0'>
              <v-list-item-title class='font-weight-semibold text-lg'>
                {{ $t('sale_report') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class='mt-0'></v-divider>
            <v-list-item-group
              v-model.trim='saleSelect'
              color='primary'
            >
              <v-list-item
                v-for='(item) in reportSaleGroup'
                :key='item.path'
                :to='{path:item.path}'
              >
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        v-if='reportDrugGroup.length > 0'
        cols='12'
        md='6'
        lg='3'
        class='pt-0'
      >
        <v-card>
          <v-list nav>
            <v-list-item class='my-0'>
              <v-list-item-title class='font-weight-semibold'>
                {{ $t('drug_equipment_report') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class='mt-0'></v-divider>

            <v-list-item-group
              v-model.trim='drugEquipmentSelect'
              color='primary'
            >
              <v-list-item
                v-for='(item, index) in reportDrugGroup'
                :key='index'
                :to='item.path'
              >
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        v-if='reportCustomerGroup.length > 0'
        cols='12'
        md='6'
        lg='3'
        class='pt-0'
      >
        <v-card>
          <v-list nav>
            <v-list-item class='my-0'>
              <v-list-item-title class='font-weight-semibold'>
                {{ $t('customer_data_report') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class='mt-0'></v-divider>
            <v-list-item-group
              v-model.trim='customerSelect'
              color='primary'
            >
              <v-list-item
                v-for='(item, index) in reportCustomerGroup'
                :key='index'
                :to='item.path'
              >
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        v-if='reportCostGroup.length > 0'
        cols='12'
        md='6'
        lg='3'
        class='pt-0'
      >
        <v-card>
          <v-list nav>
            <v-list-item class='my-0'>
              <v-list-item-title class='font-weight-semibold'>
                {{ $t('ExpenseReport') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class='mt-0'></v-divider>
            <v-list-item-group
              v-model.trim='costSelect'
              color='primary'
            >
              <v-list-item
                v-for='(item, index) in reportCostGroup'
                :key='index'
                :to='item.path'
              >
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        v-if='reportAnalysisGroup.length > 0'
        cols='12'
        md='6'
        lg='3'
        class='pt-0 '
      >
        <v-card>
          <v-list nav>
            <v-list-item class='my-0'>
              <v-list-item-title class='font-weight-semibold'>
                {{ $t('reportAnalysisData') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class='mt-0'></v-divider>
            <v-list-item-group
              v-model.trim='reportAnalysisGroup'
              color='primary'
            >
              <v-list-item
                v-for='(item, index) in reportAnalysisGroup'
                :key='index'
                :to='item.path'
              >
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        v-if='reportAccountGroup.length > 0'
        cols='12'
        md='6'
        lg='3'
        class='pt-0 '
      >
        <v-card>
          <v-list nav>
            <v-list-item class='my-0'>
              <v-list-item-title class='font-weight-semibold'>
                {{ $t('AccountingReport') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class='mt-0'></v-divider>
            <v-list-item-group
              v-model.trim='accountingSelect'
              color='primary'
            >
              <v-list-item
                v-for='(item, index) in reportAccountGroup'
                :key='index'
                :to='item.path'
              >
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        v-if='reportExaminationGroup.length > 0'
        cols='12'
        md='6'
        lg='3'
        class='pt-0 '
      >
        <v-card>
          <v-list nav>
            <v-list-item class='my-0'>
              <v-list-item-title class='font-weight-semibold'>
                {{ $t('InspectionExaminationReport') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class='mt-0'></v-divider>
            <v-list-item-group
              v-model.trim='checkSelect'
              color='primary'
            >
              <v-list-item
                v-for='(item, index) in reportExaminationGroup'
                :key='index'
                :to='item.path'
              >
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        v-if='reportWarningGroup.length > 0'
        cols='12'
        md='6'
        lg='3'
        class='pt-0 '
      >
        <v-card>
          <v-list nav>
            <v-list-item class='my-0'>
              <v-list-item-title class='font-weight-semibold'>
                {{ $t('OpiateReport') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class='mt-0'></v-divider>
            <v-list-item-group
              v-model.trim='controlSelect'
              color='primary'
            >
              <v-list-item
                v-for='(item, index) in reportWarningGroup'
                :key='index'
                :to='item.path'
              >
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-else
      class='mt-5'
    >
      <v-col cols='12'>
        <div align='center'>
          <span class='font-weight-semibold primary--text'>
            <div class='loader'>{{ $t('data_loading') }}</div>
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useReport from './useReport'

export default {
  setup() {
    return {
      ...useReport(),
    }
  },
}
</script>
<style lang='scss'>
@import '~vuetify/src/styles/styles.sass';

.loader,
.loader:before,
.loader:after {
  background: var(--v-primary-base);
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: var(--v-primary-base);
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

</style>
